@import "./../../../assets/styles/variables";

.awssld {
    --organic-arrow-thickness: 4px;
    --organic-arrow-height: 40px;
    --slider-height-percentage: 20%;
    --loader-bar-color: #851515;
    --loader-bar-height: 6px;
    --control-button-width: 10%;
    --control-button-height: 25%;
    --control-button-opacity: 0.5;
    --control-button-hover-opacity: 0.75;
    --control-button-background: transparent;
    --transition-bezier: cubic-bezier(0.5, 0.075, 0.25, 0.95);
    --slider-transition-duration: 575ms;
    --organic-arrow-color: #6a6a6a;
    --organic-arrow-border-radius: 0;
    --control-bullet-color: #9b9b9b;
    --control-bullet-active-color: #ffffff;
    --content-background-color: #2f2f2f;
    .awssld__bullets button {
        width: 10px;
        height: 10px;
    }
    .awssld__bullets .awssld__bullets--active {
        transform: scale(1.3);
    }
}


.slick-list{
    max-height: 400px
}
