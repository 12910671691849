@import "./../../assets/styles/variables";

.row_list {
    padding: 0;
    margin: 10px;
    width: calc(100% - 20px);
}

.boder_ {
    border-top: 1px solid #4a4a4a;
    margin: 15px 0px;
}

.MenuItem {
    color: black;
}

/* Increase the specificity */

.css-julti5-MuiSwitch-root{
    padding: 4px !important;
}

.css-1yjjitx-MuiSwitch-track{
    background-color: #4A4A4A !important;
    border-radius: 25px !important;
}

.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
    color: #FFFFFF !important;
}

.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: #5CBC7C !important;
}
