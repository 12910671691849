body {
    position: absolute;
    overflow: scroll !important;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    padding: 0;
    margin: 0;
    -webkit-text-size-adjust: 100%;
    touch-action: manipulation;
}

.front-page {
    .site-btn {
        /* background: linear-gradient(180deg, rgba(91, 182, 241, 1) 0%, rgba(198, 63, 211, 1) 100%); */
        background: linear-gradient(180deg, rgba(91, 182, 241, 1) 0%, rgba(198, 63, 211, 1) 150%);
        color: #fff;
        box-shadow: #4d4d4d;
    }
    .site-btn:hover {
        background: transparent;
        color: #4d4d4d !important;
        box-shadow: #4d4d4d;
        border: 1px solid var(--site-color) !important;
    }

    .site-color {
        color: var(--site-color) !important;
    }

    .single-client {
        margin: 20px 0px;
    }

    .text_span {
        color: #6d6d6d;
        display: inline-block;
        font-size: 14px;
        font-weight: 500;
        text-align: justify;
    }
    .full-width {
        width: 100%;
    }

    .second-eight {
        background: #fff;
    }
}
