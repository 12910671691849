@import "./../../assets/styles/variables";

.back_inputColor {
    background-color: $inputColor;
}

.api_list_back {
    background: #8E8E8E;
}
.api_list_back_active {
    background: linear-gradient(
        180deg,
        rgba(242, 207, 110, 1) 0%,
        rgba(239, 180, 71, 1) 40%,
        rgba(180, 121, 17, 1) 100%
    );
}
