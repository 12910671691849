@import "./../../assets/styles/variables";

.auth_page {
  .card-center {
    height: 100%;
    .card {
      border-radius: 0;
      margin: 0;
      padding: 0;
      height: 100%;
      background-color: $mainColor;
      .content{
        margin: 0 auto;
      }
      .forgot-password {
        text-align: end;
        a {
          color: #8e8e8e;
          text-decoration: none;
        }
      }
      .login_guest_button {
        border: none;
        border-radius: 5px;
        background-image: linear-gradient(to bottom, #1e2333, #1e2333);
      }
      .login_button {
        border: none;
        border-radius: 5px;
        background-image: linear-gradient(to bottom, #5cbc7c, #5cbc7c);
      }
      .register_text {
        margin-top: 10px;
        color: #8e8e8e;
        font-size: 14px;
        text-align: center;
        .register_link {
          text-decoration: none;
          color: #446CE3 !important;
        }
      }
    }
  }
}
