@import "./../../assets/styles/variables";

.qr_code {
  background: #ffffff;
  padding: 10px;
  width: fit-content;
  margin: 0 auto;
}

.invite_back {
  background: linear-gradient(180deg, rgba(31, 64, 157, 1) 0%, rgba(45, 36, 99, 1) 50%, rgba(78, 15, 130, 1) 100%);
  min-height: 100%;
}
