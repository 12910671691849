.main_height {
    min-height: 100vh;
}
.dark_mode_on {
    background-color: "#FFFFFF";
}

.dark_mode_off {
    background-color: "#07051F";
}

.text_box_common {
    background-color: "#FFFFFF";
    border: 0ch;
    padding: 100;
}

.trad_bot {
    .main_content {
        margin-top: 0px;
        text-align: right;
        display: flex;
        align-items: center;
        justify-content: right;
        .ant-form-item-control-input-content {
            text-align: -webkit-right;
            .ant-input {
                background-color: transparent;
                color: rgb(255, 255, 255);
                width: 50px;
                text-align: center;
            }
        }
    }
}

.sweet-alert {
    color: #000000;
}

.btn-success_btn {
    background-color: #5cbc7c;
}

.back_success {
    background-color: #d4edda;
}
.back_danger {
    background-color: #f8d7da;
}

.rsis-container div{
    background-position: center;
}