@import "./../../assets/styles/variables";

.list_item {
    background: #1e2333;
    padding: 10px;
    margin: 10px;
    border-radius: 5px;
    .text_up {
        color: #adfc83;
    }
    .text_down {
        color: #fc8383;
    }
    .cycle {
        background-color: #1f2b44;
        padding: 0px 5px;
        color: #446ce3;
        border-radius: 5px;
    }
    .avg_label {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .avg_price {
            background-color: #07051f;
            padding: 2px 15px;
            border-radius: 5px;
        }
    }
    .list_details {
        font-size: 14px;
    }
}
