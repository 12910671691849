@import "./../../assets/styles/variables";

.main_header {
    border-radius: 0px;
    left: 0px !important;
    right: 0px !important;
    min-height: 60px;
    padding-top: 0px !important;
    margin-top: calc(env(safe-area-inset-top) * 0.8) !important;
    box-shadow: 0 0px 15px 0 rgb(0 0 0 / 10%) !important;
    background-color: $blackColor !important;
    .header-title {
        color: #ffffff !important;
    }
}
.main_footer {
    left: 0 !important;
    right: 0 !important;
    border-radius: 0;
    bottom: 0 !important;
    // background-color: $mainHighlightColor !important;
    background-color: #000 !important;
    .menu {
        color: #fff !important;
        margin-top: 5px;
        display: block;
        font-size: 18px;
        position: relative;
        z-index: 2;
        position: absolute !important;
        width: 48px;
        height: 48px;
        left: 50%;
        margin-left: -24px;
        transform: translateY(-12px);
        border-radius: 22px;
        line-height: 48px;
        img {
            width: 24px;
            height: 24px;
        }
        span {
            opacity: 1 !important;
            color: #ffffff !important;
            font-size: 14px !important;
        }
    }
    .active-nav {
        .menu {
            background-color: #446CE3;
        }
    }
    .show-offline {
        .menu {
            background-color: $mainHighlightColor;
        }
    }
}

.page-content-sub-layout{
    padding-bottom: 65px !important;
}
