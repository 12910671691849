@import "./../../assets/styles/variables";

.back_inputColor {
    background-color: $inputColor;
}

.list_back_active {
    // background: linear-gradient(
    //     180deg,
    //     rgba(242, 207, 110, 1) 0%,
    //     rgba(239, 180, 71, 1) 40%,
    //     rgba(180, 121, 17, 1) 100%
    // );
    background: linear-gradient(180deg, rgba(91, 182, 241, 1) 0%, rgba(198, 63, 211, 1) 100%);
}
.api_list_back_wallet {
    background: linear-gradient(180deg, rgba(91, 182, 241, 1) 0%, rgba(198, 63, 211, 1) 100%);
    padding: 3px;
    border-radius: 5px;
}

.border_ {
    border: 0.1px solid #2e303d;
}

.text_box_main {
    width: calc(100% - 75px);
    display: inline-block;
    height: 100%;
    .text_box {
        width: 100%;
        display: inline-block;
        height: 100%;
        background: transparent;
        border: none;
        color: #ffffff !important;
        border-radius: 0px !important;
    }
}

.text_box_main_otp {
    width: calc(100% - 100px);
    display: inline-block;
    height: 100%;
    .text_box {
        width: 100%;
        display: inline-block;
        height: 100%;
        background: transparent;
        border: none;
        color: #ffffff !important;
        border-radius: 0px !important;
    }
}
