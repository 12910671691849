$body-bg: #fff;

//Fonts settings

// $body-fonts: 'Telegraf', sans-serif;
// $headings-font-family: 'Telegraf', sans-serif;

$body-fonts: "Nunito", sans-serif !important;
$headings-font-family: "Nunito", sans-serif !important;



// Colors
$mainColor: #07051F;
$mainHighlightColor: #1c0058;
$blackColor: #000;
$white: #fff;
$formBorderColor: #e5e5e5;
$inputColor: #5c5c5c;
$black: #000;
$bgPrimaryBtn: #787878;
$logoColor: #576acd;
$lightText: #7a7a7a;
$MiddleLightText: #858585;
$lightbg: #f5f5f5;
$primaryColor: #2a5f9e;
$secondaryColor: #6861E9;
$btnSecondaryBg: #424242;
$notification: #eb4969;
$profitColor: #00c092;
$lossColor: #ed4c5c;


// Shadow
$default-shadow: 0px 2px 2px rgba(0, 0, 0, 0.08);

// Transition
$transition-animate: all ease 0.4s;
