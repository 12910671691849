@import "./../../../assets/styles/variables";

.menu_card {
    .row{
        margin: 10px 5px;
        .width-20 {
            // width: 20%;
            align-self: center;
            padding: 0px;
            .text_icon{
                margin-top: 5px;
                font-size: 12px;
                line-height: 1.5;
            }
        }
    }
}

