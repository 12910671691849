$color-white: #fff;
$brand-color-g: var(--site-color);
$brand-color: #df8625;

@mixin respond-to($breakpoint) {
    @if $breakpoint == "xs" {
        @media screen and (max-width: 767px) {
            @content;
        }
    } @else if $breakpoint == "sm" {
        @media (min-width: 768px) and (max-width: 991px) {
            @content;
        }
    } @else if $breakpoint == "md" {
        @media (min-width: 992px) and (max-width: 1199px) {
            @content;
        }
    } @else if $breakpoint == "lg" {
        @media screen and (min-width: 1200px) {
            @content;
        }
    }
}

.ps-timeline-sec {
    position: relative;
    .container {
        position: relative;

        ol {
            @include respond-to(xs) {
                &:before {
                    background: $brand-color-g;
                    content: "";
                    width: 10px;
                    height: 10px;
                    border-radius: 100%;
                    position: absolute;
                    top: 0px !important;
                    left: 26px !important;
                }

                &:after {
                    background: var(--site-color);
                    content: "";
                    width: 10px;
                    height: 10px;
                    border-radius: 100%;
                    position: absolute;
                    top: inherit !important;
                    left: 26px;
                }

                &.ps-timeline {
                    margin: 10px 0 !important;
                    border-left: 2px solid var(--site-color);
                    padding-left: 0 !important;
                    padding-top: 100px !important;
                    border-top: 0 !important;
                    margin-left: 10px !important;

                    li {
                        height: 120px;
                        float: none !important;
                        width: inherit !important;

                        span {
                            left: 0 !important;

                            &.ps-sp-top {
                                &:before {
                                    content: none !important;
                                }

                                &:after {
                                    content: none !important;
                                }
                            }

                            &.ps-sp-bot {
                                &:before {
                                    content: none !important;
                                }

                                &:after {
                                    content: none !important;
                                }
                                .dot {
                                    width: 80%;
                                    height: 80%;
                                    background: var(--site-color);
                                    margin: 4.5px;
                                    border-radius: 100%;
                                }
                            }
                        }

                        .img-handler-top {
                            position: absolute !important;
                            bottom: 125px !important;
                            width: 100% !important;
                            float: left !important;
                            margin-left: 35px !important;
                            margin-bottom: 0 !important;

                            text-align: left !important;
                            font-weight: bold;
                            color: var(--site-color);
                        }

                        .img-handler-bot {
                            position: absolute !important;
                            bottom: 125px !important;
                            width: 100% !important;
                            float: left !important;
                            margin-left: 35px !important;
                            margin-bottom: 0 !important;
                            
                            text-align: left !important;
                            font-weight: bold;
                            color: var(--site-color);
                        }

                        p {
                            text-align: left !important;
                            width: 100% !important;
                            margin: 0 auto !important;
                            margin-top: 0px !important;
                            font-weight: bold;
                        }

                        .ps-top {
                            width: 95% !important;
                            float: left !important;
                            left: 0;
                            top: 10px;
                            margin-left: 35px !important;
                        }

                        .ps-bot {
                            width: 95% !important;
                            float: left !important;
                            left: 0;
                            top: -25px;
                            margin-left: 35px !important;
                        }
                    }
                }
            }

            &:before {
                background: $brand-color-g;
                content: "";
                width: 10px;
                height: 10px;
                border-radius: 100%;
                position: absolute;
                left: 8px;
                top: -4px;
            }

            &:after {
                background: $brand-color-g;
                content: "";
                width: 10px;
                height: 10px;
                border-radius: 100%;
                position: absolute;
                right: 8px;
                top: -4px;
            }

            &.ps-timeline {
                margin: 250px 0;
                padding: 0;
                border-top: 2px solid $brand-color-g;
                list-style: none;

                li {
                    float: left;
                    width: 25%;
                    padding-top: 30px;
                    position: relative;

                    span {
                        width: 50px;
                        height: 50px;
                        margin-left: -25px;
                        background: $color-white;
                        border: 4px solid $brand-color-g;
                        border-radius: 50%;
                        box-shadow: 0 0 0 0px $color-white;
                        text-align: center;
                        line-height: 50px -10;
                        color: $brand-color;
                        font-size: 2em;
                        font-style: normal;
                        position: absolute;
                        top: -26px;
                        left: 50%;

                        &.ps-sp-top {
                            .dot {
                                width: 80%;
                                height: 80%;
                                background: var(--site-color);
                                margin: 4.5px;
                                border-radius: 100%;
                            }
                            &:before {
                                content: "";
                                color: $brand-color-g;
                                width: 2px;
                                height: 50px;
                                background: $brand-color-g;
                                position: absolute;
                                top: -50px;
                                left: 50%;
                            }

                            &:after {
                                content: "";
                                color: $brand-color-g;
                                width: 8px;
                                height: 8px;
                                background: $brand-color-g;
                                position: absolute;
                                bottom: 90px;
                                left: 44%;
                                border-radius: 100%;
                            }
                        }

                        &.ps-sp-bot {
                            .dot {
                                width: 80%;
                                height: 80%;
                                background: var(--site-color);
                                margin: 4.5px;
                                border-radius: 100%;
                            }
                            &:before {
                                content: "";
                                color: $brand-color-g;
                                width: 2px;
                                height: 50px;
                                background: $brand-color-g;
                                position: absolute;
                                bottom: -50px;
                                left: 50%;
                            }

                            &:after {
                                content: "";
                                color: $brand-color-g;
                                width: 8px;
                                height: 8px;
                                background: $brand-color-g;
                                position: absolute;
                                top: 90px;
                                left: 44%;
                                border-radius: 100%;
                            }
                        }
                    }

                    .img-handler-top {
                        text-align: center;
                        position: absolute;
                        bottom: 0;
                        margin-bottom: 130px;
                        width: 100%;
                        font-weight: bold;
                        color: var(--site-color);

                        img {
                            display: table;
                            margin: 0 auto;
                        }
                    }

                    .img-handler-bot {
                        text-align: center;
                        position: absolute;
                        margin-top: 60px;
                        width: 100%;
                        font-weight: bold;
                        color: var(--site-color);

                        img {
                            display: table;
                            margin: 0 auto;
                        }
                    }

                    p {
                        text-align: center;
                        width: 80%;
                        margin: 0 auto;
                        font-weight: bold;
                    }

                    .ps-top {
                        position: absolute;
                        bottom: 0;
                        margin-bottom: 100px;
                        width: 100%;
                    }

                    .ps-bot {
                        position: absolute;
                        margin-top: 35px;
                        width: 100%;
                    }
                }
            }
        }
    }
}
